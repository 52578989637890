<template>
  <v-container fluid dark outlined>
    <v-card dark outlined>
      <v-card-title class="text-h6">
        {{ formTitle }}
      </v-card-title>
      <v-divider></v-divider>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <!-- Header -->
          <v-row>
            <v-col>
              <label style="padding-left: 12px">
                <B>Event Name: </B>
                {{ selectedEvent.EventName ? selectedEvent.EventName : "N/A" }}
                <p></p>

              </label>
              <br />
              <br />
              <label style="padding-left: 12px">
                <B>Stallholder Name: </B>
                {{
                  selectedStallholder.StallholderName
                    ? selectedStallholder.StallholderName
                    : "No Stallholder selected"
                }}
              </label>
            </v-col>
          </v-row>

          <!-- Stall selection -->
          <v-row>
            <v-col cols="3" class="mt-3">
              <v-combobox
                v-model="this.selectedStalls"
                multiple
                :items="this.selectedStallholder.Stalls"
                return-object
                label="Stalls"
                item-text="StallName"
                style="padding-left: 12px"
                @change="updateSelectedStalls($event)"
              >
              </v-combobox>
            </v-col>
            <v-col cols="1"></v-col>
                <!-- Layout Name -->
                <v-col cols="4" class="mt-3">
                  <v-text-field
                    v-model=layoutName
                    label="Layout Name"
                    required
                    style="padding-right: 6px"
                  ></v-text-field>
                </v-col>
          </v-row>

          
          <v-container fluid>
            <!-- Data entry row 1 -->
            <div v-if="layoutName">
              <v-row
                v-for="(categoryItem, index) in layout.Objects"
                :key="index"
              >
                <!-- Index -->
                <v-col cols="1" class="mt-3">
                  <v-container>
                    <v-row>
                      <v-col>
                        <p class="blue--text">{{ index + 1 }}</p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>

                <!-- Group or product -->
                <v-col
                  cols="1"
                  class="mt-5"
                  style="padding-right: 0px !important"
                >
                  <v-autocomplete
                    :items="['Group', 'Product']"
                    v-model="categoryItem.itemType"
                    return-object
                    filled
                    dense
                    outlined
                    hide-details
                    label="Type"
                    @change="changeItemTypeSelection($event, index)"
                  >
                  </v-autocomplete>
                </v-col>

                <!-- Group or Product Name -->
                <v-col cols="2" class="mt-3" v-if="itemTypeIsGroup[index]">
                  <v-text-field
                    v-model="categoryItem.GroupName"
                    label="Group Name"
                    required
                    style="padding-right: 6px"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="2"
                  class="mt-3"
                  v-if="!itemTypeIsGroup[index]"
                  style="padding-top: 20px"
                >
                  <v-autocomplete
                    :items="allProducts"
                    v-model="categoryItem.ProductId"
                    item-text="ProductName"
                    return-object
                    filled
                    dense
                    outlined
                    hide-details
                    label="Product Name"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon small color="red lighten-1">
                            mdi-information
                          </v-icon>
                          No products found
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <!-- Group type -->
                <v-col
                  cols="1"
                  class="mt-5"
                  style="padding-right: 0px !important"
                  v-if="itemTypeIsGroup[index]"
                >
                  <v-autocomplete
                    :items="['Standard', 'Selection']"
                    v-model="categoryItem.GroupType"
                    item-text="text"
                    return-object
                    filled
                    dense
                    outlined
                    hide-details
                    label="Type"
                    @change="changeGroupSelection($event, index)"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon small color="red lighten-1">
                            mdi-information
                          </v-icon>
                          No event found
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <!-- Selection group name -->
                <v-col
                  cols="2"
                  class="mt-3"
                  style="padding-left: 24px !important"
                  v-if="
                    groupTypeSelection[index] && itemTypeIsGroup[index]
                  "
                >
                  <v-text-field
                    v-model="categoryItem.SelectionName"
                    label="Selection Name"
                  ></v-text-field>
                </v-col>

                <!-- Text colour -->
                <v-col cols="1" class="mt-5">
                  <v-autocomplete
                    :items="ColourList"
                    v-model="categoryItem.textColour"
                    item-text="ColourName"
                    return-object
                    filled
                    dense
                    outlined
                    hide-details
                    label="Text"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon small color="red lighten-1">
                            mdi-information
                          </v-icon>
                          No event found
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <!-- Background colour -->
                <v-col cols="1" class="mt-5">
                  <v-autocomplete
                    :items="ColourList"
                    v-model="categoryItem.backgroundColour"
                    item-text="ColourName"
                    return-object
                    filled
                    dense
                    outlined
                    hide-details
                    label="Background"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon small color="red lighten-1">
                            mdi-information
                          </v-icon>
                          No event found
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-card-actions>
                <!-- Manage button -->
                  <v-col>
                    <v-btn
                      text
                    color="blue"
                      small
                      v-show="
                        itemTypeIsGroup[index] &&
                        layout.Objects.length > 1
                      "
                      @click="addItem(index)"
                      >Manage Items
                    </v-btn>
                  </v-col>

                <!-- Add and remove buttons -->
                  <v-col>
                    <v-btn
                      text
                    color="blue"
                      small
                      v-show="
                        index || (!index && layout.Objects.length > 1)
                      "
                      @click="removeItem(index)"
                      >Delete
                    </v-btn>
                  </v-col>

                  <v-col>
                    <v-icon
                      small
                      color="blue"
                      @click="moveItemUp($event, index)"
                    >
                      mdi-arrow-up
                    </v-icon>
                    <v-icon
                      small
                      color="blue"
                      @click="moveItemDown($event, index)"
                    >
                      mdi-arrow-down
                    </v-icon>
                  </v-col>
                </v-card-actions>
                
                <v-container>
                          <!-- Delete button  -->
                          <v-row>
                            <v-col offset-md="5">
                              <v-btn
                                color="blue darken-1"
                                small
                                text
                                v-show="
                                  index ==
                                  layout.Objects.length - 1
                                "
                                @click="addObject(index)"
                                >Add Item
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>

                <v-container>
                  <template v-if="layout.Objects.length > 1">
                    <v-list-item><v-divider></v-divider></v-list-item>
                  </template>
                </v-container>
              </v-row>
            </div>
          </v-container>
        </v-card>
      </v-form>

      <!-- Cancel and save buttons-  -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="save">Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import "izitoast/dist/css/iziToast.min.css"; // loading css
import iziToast from "izitoast/dist/js/iziToast.min.js"; // you have access to iziToast now
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data: () => ({
    groupTypes: [],
    groupTypeSelection: [],
    groupType: true,
    itemTypeVal: "Group",
    editedLayout: {
      Objects: [ {} ],
    },
    allProducts: [],
    stalls: [],
    LayoutList: [],
    layoutName: '',
    ColourList: [],
    stallHolders: [],
    selectedStalls: [],
    editedIndex: -1,
    layoutId: '',
    editedItem: {
      Layout: {
        root: {
          Groups: [],
          Products: []
        }
      }
    },
    isRootItemVisible: false,
    valid: true,
    itemTypeIsGroup: [
    ],
    action: '',
    test:'',
  }),

  computed: {
    ...mapState("filter", [
      "globalEventFilter",
      "allEvents",
      "allStallHolders",
    ]),
    ...mapState("admin", [
      "processedLayout"
    ]),

    formTitle() {
      return this.editedIndex === -1 ? "New layout" : "Edit layout";
    },

    layout: {
      get() {        
        if (this.processedLayout.Objects === []) {
          return { Objects: [1], }
          } else {
          return this.editedLayout
          }
      },
      set() {
        this.editedLayout = this.processedLayout
      },
    },
    

    selectedEvent: {
      get() {
        return this.globalEventFilter;
      },
      set(value) {
        console.log("EVENT IS CHANGED MAKE API REQUEST ----1", value);
      },
    },

    selectedStallholder: {
      get() {
        let setStallholder = JSON.parse(
          localStorage.getItem("setStallholderObj")
        );
        return setStallholder;
      },
      set(value) {
        this.stalls = value;
      },
    },
    events() {
      return this.allEvents.map((obj) => {
        return { text: obj.EventName, value: obj.EventId };
      });
    },
  },

  watch: {
    layout: function () {
      this.layout
    },
    layoutName: function () {
      localStorage.setItem('editedLayoutName', this.layoutName)
    }
  },

  created() {
    this.getAllProducts();
    this.getAllColours();
    this.setStalls();
    this.setNewLayout()
  },

  methods: {
    ...mapMutations("admin", [
      "setLayout"
    ]),
    ...mapMutations("filter", [
      "setGlobalEventFilter",
      "setAllEvents",
      "setFilterStallHolders",
    ]),
    ...mapActions("admin", [
      "getProducts",
      "getLayouts",
      "postLayouts",
      "getColours",
      "createEvent",
    ]),

    setStalls: function () {
      this.stalls = JSON.parse(
        localStorage.getItem("setStallholderObj")
      ).Stalls;
    },

    getAllProducts() {
      let eventId = localStorage.getItem("setSelectedEventId");
      let StallholderId = localStorage.getItem("setSelectedStallholderId");
      let paramObj = {};
      paramObj.EventId = eventId;
      paramObj.StallholderId = StallholderId;
      this.getProducts(paramObj).then((response) => {
        this.allProducts = response.Products;
      });
    },

    getAllColours() {
      this.getColours().then((response) => {
        this.ColourList = response;
      });
    },

    changeGroupSelection: function (event, index) {
      this.groupTypeSelection[index] = event === "Selection" ? true : false;
    },

    changeItemTypeSelection: function (event, index) {
      console.log('changeItemTypeSelection',event)
      this.isLevel1ItemVisible =
        event === "Group" ? true : this.isLevel1ItemVisible;
      this.itemTypeIsGroup[index] = event === "Group" ? true : false;
    },
    
    updateSelectedStalls: function (event) {
      this.selectedStalls = []
      for (let i = 0; i < event.length; i++) {
        let new_stall = {}
        new_stall['StallId'] = event[i].StallId
        new_stall['StallName'] = event[i].StallName
        this.selectedStalls.push(new_stall)
      }
      
      console.log('this.selectedStalls',this.selectedStalls)
    },

    setNewLayout: function () {
      console.log('Starting setNewLayout')

      // this.layoutName = localStorage.getItem('editedLayoutName')
        
      // let setEvent = JSON.parse(
      //     localStorage.getItem("setEventObj")
      //   );
      // console.log('setNewLayout eventid',setEvent)

      if (this.$route.params.id !== 'new') {

        let layoutItem = JSON.parse(localStorage.getItem('setLayoutObj'))
        console.log('setNewLayout layoutItem',layoutItem)
        this.processedLayout.Objects = layoutItem.Objects

        this.layoutName = layoutItem.LayoutName
        this.selectedStalls = layoutItem.Stalls

        console.log('setNewLayout this.layoutName',this.layoutName)
        console.log('setNewLayout item',layoutItem)
      }
      
      if (!this.processedLayout.Objects) {
          this.setLayout({ Objects: [], }
            )
            this.$forceUpdate()
          } else {
            for (let i = 0; i < this.processedLayout.Objects.length; i++) {
            this.itemTypeIsGroup[i] = this.processedLayout.Objects[i].itemType === 'Group' ? true : false
            this.groupTypeSelection[i] = this.processedLayout.Objects[i].GroupType === 'Selection' ? true : false
          }
          this.layout = this.processedLayout
          this.$forceUpdate()
          }
            
        if (this.$route.params.id === 'create') {
          this.Action = 'create';
        } else {
          this.Action = 'update';
        }
    },

    addItem: function (index) {   

      this.$router.push({
        name: 'Layout Configuration 1',
        params: { id: index }
      })
  },

    addObject: function (index) {
      this.setLayout(this.layout)      
      this.isRootItemVisible = true;
      // Validation checks:
      let x = this.layout.Objects.length - 1;

      this.itemTypeIsGroup[index] = this.layout.Objects[index].itemType === 'Group' ? true : false
      this.groupTypeSelection[index] = this.layout.Objects[index].GroupType === 'Selection' ? true : false

      let g1 = this.layout.Objects[x].itemType === 'Group' ? this.layout.Objects[x].GroupType : true
      let g2 = (this.layout.Objects[x].itemType === 'Group' && this.layout.Objects[x].GroupType === 'Selection') ? this.layout.Objects[x].SelectionName : true

      if (
        (this.layout.Objects[x].GroupName || this.layout.Objects[x].ProductId) &&
        g1 &&
        g2 &&
        this.layout.Objects[x].textColour &&
        this.layout.Objects[x].backgroundColour
      ) {
        this.layout.Objects[x].level = 1
        this.layout.Objects[x].position = index + 1
        this.layout.Objects.push({});
      }
    },

    removeItem: function (index) {
      this.groupTypeSelection.splice(index, 1);
      this.itemTypeIsGroup.splice(index, 1);
      this.layout.Objects.splice(index, 1);
    },

    moveItemUp(event, index) {
      if (index > 0) {
        let objectElement = this.layout.Objects[index];
        this.layout.Objects[index] = this.layout.Objects[index - 1];
        this.layout.Objects[index - 1] = objectElement;

        let itemTypeElement = this.itemTypeIsGroup[index];
        this.itemTypeIsGroup[index] = this.itemTypeIsGroup[index - 1];
        this.itemTypeIsGroup[index - 1] = itemTypeElement;

        let groupTypeElement = this.groupTypeSelection[index];
        this.groupTypeSelection[index] = this.groupTypeSelection[index - 1];
        this.groupTypeSelection[index - 1] = groupTypeElement;

        this.$forceUpdate();
      }
    },

    moveItemDown(event, index) {
      if (index !== -1 && index < this.layout.Objects.length - 1) {
        let objectElement = this.layout.Objects[index];
        this.layout.Objects[index] = this.layout.Objects[index + 1];
        this.layout.Objects[index + 1] = objectElement;

        let itemTypeElement = this.itemTypeIsGroup[index];
        this.itemTypeIsGroup[index] = this.itemTypeIsGroup[index + 1];
        this.itemTypeIsGroup[index + 1] = itemTypeElement;

        let groupTypeElement = this.groupTypeSelection[index];
        this.groupTypeSelection[index] = this.groupTypeSelection[index + 1];
        this.groupTypeSelection[index + 1] = groupTypeElement;

        this.$forceUpdate();
      }
    },

    close() {
      this.setLayout({})
      this.groupTypeSelection = [];
      this.itemTypeIsGroup = [];
      this.isRootItemVisible = false;
      
      this.$router.push({
        name: "ProductManagement",
      });
    },

    save: function () {
      this.setLayout({})
      this.groupTypeSelection = [];
      this.itemTypeIsGroup = [];
      this.isRootItemVisible = false;

      let setStallholder = JSON.parse(
          localStorage.getItem("setStallholderObj")
        );
      let setEvent = JSON.parse(
          localStorage.getItem("setEventObj")
        );

      if (this.$refs.form.validate()) {

    try {
      if (this.$refs.form.validate()) {

        let eventSubmitItem =
          {
              Type: "Layout",
              StallholderId: setStallholder.StallholderId,
              EventId: setEvent,
              LayoutName: this.layoutName,
              Action: this.Action,
              Stalls: this.selectedStalls,
              Layout: this.layout
          }        
          
        let layoutItem = JSON.parse(localStorage.getItem('setLayoutObj'))        
        if (layoutItem.LayoutId) {
          eventSubmitItem['LayoutId'] = layoutItem.LayoutId
        }
        
        console.log('REQUEST ITEM: ',eventSubmitItem)
          
        this.postLayouts(eventSubmitItem).then(response => {

            iziToast.success({
                title: 'Device',
                message: 'Successfully inserted record!',
                position: 'topRight'
            });

            console.log('save() Response',response)

            this.redirectReload();

        }) 

      }
    } catch (e) {
      console.log("ERROR: ",e)
    }
    }
      
      this.$router.push({
        name: "ProductManagement",
      });

    },
  },
};
</script>

<style scoped>
h1 {
  color: #a09c94;
  font-family: sans-serif;
}
</style>